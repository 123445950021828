import { createAction, createAsyncFormAction } from 'utils'
import { customerMaster } from 'pages/CustomerMaster/constants'
import * as CONSTANTS from './constants'

/* open the Customer Ledger */
export const getCustomerLedgerMeta = createAsyncFormAction(
  CONSTANTS.GET_CUSTOMER_LEDGER_META,
  customerMaster
)

export const readCustomerLedgerByTab = createAsyncFormAction(
  CONSTANTS.READ_CUSTOMER_LEDGER_BY_TAB,
  customerMaster
)

export const setSummaryExpansionPanelState = ({ expanded }) =>
  createAction(
    CONSTANTS.SET_SUMMARY_EXPANSION_PANEL_STATE,
    { expanded },
    customerMaster
  )

export const setAgingSummaryDefaultView = ({ agingView }) =>
  createAction(
    CONSTANTS.SET_AGING_SUMMARY_DEFAULT_VIEW,
    { agingView },
    customerMaster
  )

/* START disputed notes modal actions */
export const openLedgerNotesModal = ({
  appendToFinanceCharge,
  dataId,
  detailGrid,
  gridName,
  id,
  invoiceType,
  note,
  parentId,
  type
}) =>
  createAction(
    CONSTANTS.OPEN_LEDGER_NOTES_MODAL.TRY,
    {
      appendToFinanceCharge,
      dataId,
      detailGrid,
      gridName,
      id,
      invoiceType,
      note,
      parentId,
      type
    },
    customerMaster
  )
export const saveLedgerNotes = createAsyncFormAction(
  CONSTANTS.SAVE_LEDGER_NOTES
)
export const lockLedgerNotes = createAsyncFormAction(
  CONSTANTS.LOCK_LEDGER_NOTES
)
export const unlockLedgerNotes = createAsyncFormAction(
  CONSTANTS.UNLOCK_LEDGER_NOTES
)
/* END disputed notes modal actions */

export const getLedgerDoc = ({ type, dataIds }) =>
  createAction(CONSTANTS.GET_LEDGER_DOC.TRY, { type, dataIds }, customerMaster)

export const printInvoices = (form, { invoices, isDirectSend = false }) =>
  createAction(CONSTANTS.PRINT_INVOICES.TRY, { invoices, isDirectSend }, form)

export const printCreditRefLetter = () =>
  createAction(CONSTANTS.PRINT_CREDIT_REF_LETTER.TRY, {}, customerMaster)

export const openSalesHistoryReportOptions = () =>
  createAction(CONSTANTS.OPEN_SALES_HISTORY_REPORT_OPTIONS, {}, customerMaster)

export const getSalesHistoryReport = ({
  dataId,
  shipToId,
  salesHistoryYear,
  printMostRecentSalesOnly,
  printPrices,
  printHistoryGrossProfit,
  printHiddenProducts,
  productLineId,
  vendorId,
  sortField,
  credentials
}) =>
  createAction(
    CONSTANTS.GET_SALES_HISTORY_REPORT.TRY,
    {
      dataId,
      shipToId,
      salesHistoryYear,
      printMostRecentSalesOnly,
      printPrices,
      printHistoryGrossProfit,
      printHiddenProducts,
      productLineId,
      vendorId,
      sortField,
      credentials
    },
    customerMaster
  )

export const closeSalesHistoryReportModal = () =>
  createAction(CONSTANTS.CLOSE_SALES_HISTORY_REPORT_MODAL, {}, customerMaster)

export const setGridPrintSelections = ({
  dataIds,
  gridName,
  detailGrid = ''
}) =>
  createAction(
    CONSTANTS.SET_GRID_PRINT_SELECTIONS,
    { dataIds, gridName, detailGrid },
    customerMaster
  )

export const openARNotes = createAsyncFormAction(CONSTANTS.OPEN_AR_NOTES)

export const toggleAllPaymentHistory = ({ checked }) =>
  createAction(
    CONSTANTS.TOGGLE_ALL_PAYMENT_HISTORY,
    { checked },
    customerMaster
  )

export const clearCustomerLedger = () =>
  createAction(CONSTANTS.CLEAR_CUSTOMER_LEDGER, {}, customerMaster)

export const saveAgingView = ({ agingView }) =>
  createAction(CONSTANTS.SAVE_AGING_VIEW.TRY, { agingView }, customerMaster)

export const toggleAllGridPrintSelections = ({ checked, gridName }) =>
  createAction(
    CONSTANTS.TOGGLE_ALL_GRID_PRINT_SELECTIONS,
    { checked, gridName },
    customerMaster
  )

export const toggleGridPrintSelection = ({
  dataId,
  gridName,
  detailGrid,
  checkFlag,
  parentId
}) =>
  createAction(
    CONSTANTS.TOGGLE_GRID_PRINT_SELECTION,
    { dataId, gridName, detailGrid, checkFlag, parentId },
    customerMaster
  )

export const getNextARNote = createAsyncFormAction(CONSTANTS.GET_NEXT_AR_NOTE)

export const setSalesHistorySortField = (form, { sortField }) => {
  const action = createAction(
    CONSTANTS.SET_SALES_HISTORY_SORT_FIELD,
    { sortField },
    form
  )
  return action
}

export const loadLedgerRecord = (form, dataId) =>
  createAction(CONSTANTS.LOAD_LEDGER_RECORD, { dataId }, form)

export const correctLedgerMetaData = form =>
  createAction(CONSTANTS.CORRECT_LEDGER_META_DATA, {}, form)

export const getSummaryData = createAsyncFormAction(CONSTANTS.GET_SUMMARY_DATA)

export const checkUpdateSummaryData = (form) =>
  createAction(CONSTANTS.CHECK_UPDATE_SUMMARY_DATA, {}, form)

export const saveExpandedLedgerGridRows = (form, { grid, expandedRows }) =>
  createAction(
    CONSTANTS.SAVE_EXPANDED_LEDGER_GRID_ROWS,
    { grid, expandedRows },
    form
  )


export const saveSelectedLedgerGridRows = (form, { grid, selectedRows }) =>
  createAction(CONSTANTS.SAVE_SELECTED_LEDGER_GRID_ROWS, { grid, selectedRows }, form)
