import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'
import * as CONSTANTS from './constants'

export const getCustomer = ({ dataId, groupNames = ['Setup'] }) => {
  const options = createApiOptions({
    body: { dataId, groupNames },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/customerMaster/read`, options)
  // return callFetch(`${BASE_INFORM_API_URL}/customer/read2`, options)
}

export const lockMasterRecord = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customerMaster/editlock`, options)
}

export const unlockMasterRecord = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    credentials: 'include',
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customerMaster/canceledit`, options)
}

/* start tax matrix API methods */
export const openTaxMatrix = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/taxmatrix/read`, options)
}

export const lockTaxMatrix = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/taxmatrix/editlock`, options)
}

export const unlockTaxMatrix = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/taxmatrix/canceledit`, options)
}

export const saveTaxMatrix = ({
  dataId,
  productLineId,
  priceGroupId,
  shipToId,
  customerId,
  productId,
  productTaxPriceGroup,
  taxable,
  additionalTaxPercent,
  taxColumn
}) => {
  const options = createApiOptions({
    body: {
      dataId,
      productLineId,
      priceGroupId,
      shipToId,
      customerId,
      productId,
      productTaxPriceGroup,
      taxable,
      additionalTaxPercent,
      taxColumn
    },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/taxmatrix/update`, options)
}

export const deleteTaxMatrix = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/taxmatrix/delete`, options)
}

export const createTaxMatrix = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId, recordName: dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/taxmatrix/create`, options)
}

/* end tax matrix API methods */

/* delete a contact */
export const lockContact = ({ parentType, dataId, parentId }) => {
  const options = createApiOptions({
    body: { parentType, dataId, parentId },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/contact/editlock`, options)
}

export const deleteContact = ({ parentType, dataId, parentId, ...rest }) => {
  const options = createApiOptions({
    body: { parentType, dataId, parentId, ...rest },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/contact/delete`, options)
}

/* START disputed notes API methods */
export const openLedgerNote = ({ dataId, route = 'disputed', credentials }) => {
  const options = createApiOptions({
    body: { dataId, credentials },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/${route}/read`,
    options
  )
}

export const lockLedgerNote = ({ dataId, route = 'disputed', credentials }) => {
  const options = createApiOptions({
    body: { dataId, credentials },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/${route}/editlock`,
    options
  )
}

export const saveLedgerNote = ({
  dataId,
  properties = {},
  route = 'disputed',
  credentials
}) => {
  const options = createApiOptions({
    body: { dataId, properties, credentials },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/${route}/save`,
    options
  )
}

export const unlockLedgerNote = ({
  dataId,
  route = 'disputed',
  credentials
}) => {
  const options = createApiOptions({
    body: { dataId, credentials },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/${route}/cancel`,
    options
  )
}

/* MERGE CUSTOMER modal */
export const openMergeCustomerModal = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/changenumber/meta`,
    options
  )
}

export const mergeCustomer = ({ dataId, toId, mergeOvernight }) => {
  const options = createApiOptions({
    body: { dataId, toId, mergeOvernight },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/changenumber/set`,
    options
  )
}

export const deletePendingCustomerMerge = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/changenumber/deletepending`,
    options
  )
}

/* end MERGE API calls */

/* start TEMPLATE API calls */

export const closeCustomerMaster = () => {
  const options = createApiOptions({
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customermaster/close`, options)
}

export const deleteCustomerRecord = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customermaster/delete`, options)
}

export const createCustomerRecord = ({ dataId, groupNames }) => {
  const options = createApiOptions({
    body: { dataId, groupNames },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customermaster/create`, options)
}

export const lockShipToRecord = ({ dataId, parentId }) => {
  const options = createApiOptions({
    body: { dataId, parentId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customershipto/editlock`, options)
}

export const deleteShipTo = ({ dataId, parentId }) => {
  const options = createApiOptions({
    body: { dataId, parentId },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customershipto/deletefromcustomer`,
    options
  )
}

/* PO Specs API calls that were missed */
export const changePODollarLimit = ({ dataId, index, poNumber }) => {
  const options = createApiOptions({
    body: { dataId, index, poNumber },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/podollarlimits/change`,
    options
  )
}

export const deletePODollarLimit = ({ dataId, index }) => {
  const options = createApiOptions({
    body: { dataId, index },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/podollarlimits/delete`,
    options
  )
}

export const customerMasterPropertyChange = ({
  dataId,
  properties,
  groupNames,
  templateKey,
  ...rest
}) => {
  const path = templateKey
    ? `${BASE_INFORM_API_URL}/customermaster/template/propertychange`
    : `${BASE_INFORM_API_URL}/customermaster/propertychange`
  const params = templateKey
    ? { dataId, properties, groupNames, templateKey, ...rest }
    : { dataId, properties, groupNames, ...rest }

  const options = createApiOptions({
    body: params,
    method: 'post'
  })

  return callFetch(path, options)
}
