import {
  actionChannel,
  take,
  call,
  select,
  put,
  fork
} from 'redux-saga/effects'

import { getIn, toJS, is } from 'utils'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'

import * as DDICONSTANTS from 'ddiForm/constants'
import * as MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import {
  ON_PRIMARY_GRID_DATA_VALIDATED,
  UPDATE_GRID_CELL_DATA
} from 'components/EditableGrid/constants'

import { getFormSelector } from 'ddiForm/utils'

import { api } from 'services'

import * as masterActions from 'ddiForm/MasterScreen/actions'

import editableGridSagas from 'components/EditableGrid/sagas'
import { listAuditsProcess } from 'components/MasterScreen/Audit/sagas'
import { removeModal } from 'modals/actions'
import * as actions from './actions'
import * as CONSTANTS from './constants'
import { removeEmptyRow } from './utils'

const getDataId = formState =>
  getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')

export function* saveArgumentsSaga(form) {
  let parentId
  let parentType
  const parent = yield select(state =>
    getIn(state, `ddiForm.${form}.values.parent`)
  )
  if (parent) {
    parentId = getIn(parent, 'parentId')
    parentType = getIn(parent, 'parentType')
  }
  return {
    // dataId,
    parentId,
    parentType
  }
}

export function* entitySuccessListener(formListener) {
  while (true) {
    const {
      payload: { customerId, isSameShipTo, dataId, description },
      meta: { form }
    } = yield take(MASTER_CONSTANTS.GET_ENTITY.SUCCESS)

    const formState = yield select(state => getIn(state, `ddiForm.${form}`))
    const selectedPrimaryTab = getIn(
      formState,
      'masterOptions.selectedPrimaryTab'
    )

    if (customerId) {
      yield put(actions.setParentId({ parentId: customerId }))
    }

    if (isSameShipTo && dataId && description) {
      yield call(
        warningModal,
        `Ship To ${dataId} "${description}" is reserved for ${description}`,
        'System Ship To'
      )
    }

    if (form === formListener && selectedPrimaryTab === 'Audit') {
      const dataId = getIn(formState, 'values.dataId')
      // const customerId = getIn(formState, 'values.customerId')
      const showAllAudits =
        getIn(formState, 'fields.showAllAudits.value') || false

      // debugger
      yield fork(listAuditsProcess, form, {
        dataId,
        parentId: customerId,
        showAll: showAllAudits
      })
    }
  }
}

// export function* onTabChangeListener(formListener) {
//   while (true) {
//     const {
//       meta: { form },
//       payload: { selectedPrimaryTab }
//     } = yield take(DDICONSTANTS.CHANGE_FORM_TAB)

//     const formState = yield select(state => getIn(state, `ddiForm.${form}`))

//     const audits = getIn(formState, 'values.audits')
//       ? getIn(formState, 'values.audits').toJS()
//       : []

//     /* get the audits: API not done */
//     if (
//       form === formListener &&
//       selectedPrimaryTab === 'Audit' &&
//       !audits.length
//     ) {
//       const dataId = getIn(formState, 'values.dataId')
//       const parentId =
//         getIn(formState, 'values.parent.parentId') ||
//         getIn(formState, 'values.customerId')
//       yield call(listAuditsProcess, form, {
//         dataId,
//         parentId,
//         showAll: true
//       })
//     }
//   }
// }

export function* cancelEditProcess(form, modalId) {
  yield put(masterActions.preCancelEdit(form))

  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const callLocation = getIn(formState, 'values.callLocation')
  const dataId = getIn(formState, 'values.dataId')
  const parentId =
    getIn(formState, 'values.parent.parentId') ||
    getIn(formState, 'values.customerId')

  const { response, error } = yield call(api.cancelShipToEditLock, {
    dataId,
    parentId
  })

  if (response) {
    yield put(masterActions.cancelEditAsync.success(response, { form }))

    if (modalId) {
      yield put(removeModal(callLocation, modalId))
    }
  } else {
    yield put(masterActions.cancelEditAsync.failure(error, { form }))
  }
}

export function* cancelEditListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { modalId = '' }
    } = yield take(CONSTANTS.CANCEL_EDIT.TRY)
    if (form === formListener) {
      yield fork(cancelEditProcess, form, modalId)
    }
  }
}

export function* createShipToProcess(form, id) {
  const formState = yield select(state =>
    getIn(state, 'ddiForm.customerShipTo')
  )
  const dataId = id || getIn(formState, 'fields.dataId.value')
  const callLocation = getIn(formState, 'values.callLocation')
  const parentId = getIn(formState, 'values.parentId')

  yield put({
    type: CONSTANTS.CREATE_SHIP_TO.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.createShipTo, {
    parentId,
    dataId
  })

  if (response) {
    yield put({
      type: CONSTANTS.CREATE_SHIP_TO.SUCCESS,
      payload: {
        ...response,
        callLocation,
        preNewMode: false,
        newRecordCreated: true
      },
      meta: { form }
    })
    yield put({
      type: MASTER_CONSTANTS.GET_ENTITY.SUCCESS,
      meta: { form },
      payload: {
        ...response,
        callLocation,
        preNewMode: false,
        newRecordCreated: true
      }
    })
  } else {
    yield put({
      type: CONSTANTS.CREATE_SHIP_TO.FAILURE,
      payload: error,
      meta: { form }
    })
    // yield put({
    //   type: MASTER_CONSTANTS.GET_ENTITY.FAILURE,
    //   meta: { form },
    //   payload: error
    // })

    yield fork(displayValidationErrors, error)
  }
}

export function* createShipToListener(formListener) {
  while (true) {
    const {
      payload: { id },
      meta: { form }
    } = yield take(CONSTANTS.CREATE_SHIP_TO.TRY)
    if (form === formListener) {
      yield fork(createShipToProcess, form, id)
    }
  }
}

export function* saveShipToProcess(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const dataId = getIn(formState, 'fields.dataId.value')
  const parentId =
    getIn(formState, 'values.parent.parentId') ||
    getIn(formState, 'values.customerId')
  const modalId = getIn(formState, 'modalId')
  const callLocation = getIn(formState, 'values.callLocation')
  const selectedPrimaryTab =
    getIn(formState, 'masterOptions.selectedPrimaryTab') || ''
  const selectedSecondaryTab =
    getIn(formState, 'masterOptions.selectedSecondaryTab') || ''

  const editedFields =
    getIn(formState, 'editedFields') && getIn(formState, 'editedFields').toJS
      ? getIn(formState, 'editedFields').toJS()
      : []


  let properties
  if (editedFields && Array.isArray(editedFields) && editedFields.length) {
    properties = editedFields.reduce((acc, next) => {
      const field = getIn(formState, `fields.${next}`)
      const value = getIn(field, 'rowData') || getIn(field, 'value')

      if (next === 'customerStockLevels.stockMinimums') {
        /* remove any empty data rows */
        const stockMinimums = toJS(value)
        acc.customerStockLevels = {
          stockMinimums: removeEmptyRow(stockMinimums)
        }
      } else if (next === 'zipcode') {
        /* temp for zipcode text mask issue */
        acc[next] =
          value.charAt(value.length - 1) === '-'
            ? value.replace('-', '')
            : value
      } else if (next === 'vendorIds') {
        const val = toJS(value)
        acc[next] = removeEmptyRow(val)
      }
      else {
        acc[next] = toJS(value)
      }
      return acc
    }, {})
  }

  let apiParams = {
    dataId,
    form,
    parentId,
    properties: {
      ...properties
    },
    groupNames: [selectedPrimaryTab, selectedSecondaryTab]
  }

  const transformDataMethod = getIn(
    formState,
    'masterOptions.transformDataBeforeSave'
  )

  if (transformDataMethod && is.fn(transformDataMethod)) {
    apiParams = transformDataMethod(apiParams, formState)
  }

  yield put({
    type: CONSTANTS.SAVE_SHIP_TO.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.save, apiParams)

  if (response) {
    /* triggers a re-render of the Ship Tos grid */

    /*
      notify BOTH the ddiForm where Ship To was CALLED from
      and notify THIS form itself to ensure all fields,
      values, flags are removed, unset, etc.
    */
    yield put({
      type: CONSTANTS.SAVE_SHIP_TO.SUCCESS,
      payload: response,
      meta: { form: callLocation, recordType: 'customerShipTo' }
    })

    yield put({
      type: CONSTANTS.SAVE_SHIP_TO.SUCCESS,
      payload: response,
      meta: { form, recordType: 'customerShipTo' }
    })

    if (modalId) {
      yield put(removeModal(callLocation, modalId))
    }
  } else {
    /* show any applicable error messaging */
    yield fork(displayValidationErrors, error)

    yield put({
      type: CONSTANTS.SAVE_SHIP_TO.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* displayValidationErrors(error) {
  if (
    error &&
    error.validationErrors &&
    Array.isArray(error.validationErrors)
  ) {
    const errorMessageString = error.validationErrors.reduce((acc, next) => {
      acc = acc.concat(`${next.property}: ${next.message}\n`)
      return acc
    }, '')
    yield call(warningModal, errorMessageString, 'Error!')
  }
}

export function* saveShipToListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.SAVE_SHIP_TO.TRY)
    if (form === formListener) {
      yield fork(saveShipToProcess, form)
    }
  }
}

export function* getProductUOMProcess(form, dataId, rowIndex, propertyName) {
  const { response, error } = yield call(api.getProductUOM, { dataId })

  if (response) {
    yield put(
      actions.getProductUOM.success({
        uoms: response,
        dataId,
        rowIndex,
        propertyName
      })
    )
  } else {
    yield put(actions.getProductUOM.failure(error))
  }
}

export function* getProductUOMListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: {
        propertyName,
        newData: { dataId },
        rowIndex
      }
    } = yield take(ON_PRIMARY_GRID_DATA_VALIDATED)
    // debugger
    if (
      form === formListener &&
      propertyName === 'customerStockLevels.stockMinimums' &&
      dataId
    ) {
      yield fork(getProductUOMProcess, form, dataId, rowIndex, propertyName)
    }
  }
}

export function* deleteShipToProcess(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const callLocation = getIn(formState, 'values.callLocation')
  const parentId =
    getIn(formState, 'values.parent.parentId') ||
    getIn(formState, 'values.customerId')
  const modalId = getIn(formState, 'modalId')

  const description =
    getIn(formState, 'fields.description.value') ||
    getIn(formState, 'values.description')
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')

  yield call(
    confirmationModal,
    `Are you sure you want to delete Ship To - ${dataId} "${description}"`,
    'Delete?'
  )

  const action = yield take([CONFIRMED, CANCELED])

  if (action.type === CONFIRMED) {
    yield put(actions.deleteShipTo.request())

    const { response, error } = yield call(api.deleteCustomerShipTo, {
      parentId,
      dataId
    })

    if (response) {
      /*
        need to fire off two actions here, because we need to
        make sure the customerMaster reducer can hear this action
      */

      yield put({
        type: CONSTANTS.DELETE_SHIP_TO.SUCCESS,
        meta: { form },
        payload: {
          ...response,
          dataId
        }
      })

      yield put({
        type: CONSTANTS.DELETE_SHIP_TO.SUCCESS,
        meta: { form: callLocation },
        payload: {
          ...response,
          dataId
        }
      })

      if (modalId) {
        yield put(removeModal(callLocation, modalId))
      }
    } else {
      yield put(actions.deleteShipTo.failure(error))
    }
  }
}

export function* deleteShipToListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(CONSTANTS.DELETE_SHIP_TO.TRY)

    if (form === formListener) {
      yield call(deleteShipToProcess, form)
    }
  }
}

export function* openGoogleMapsProcess(form, type) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const parentAddress = getIn(formState, 'values.parent')
    ? getIn(formState, 'values.parent').toJS()
    : {}
  const addressLine1 = getIn(formState, 'values.addressLine1')
  const addressLine2 = getIn(formState, 'values.addressLine2')
  const city = getIn(formState, 'values.city')
  const state = getIn(formState, 'values.state')
  const zipcode = getIn(formState, 'values.zipcode')

  const baseAddress = 'https://www.google.com/maps?q='
  let urlParts = ''
  if (Object.keys(parentAddress).length) {
    const { address1, address2, cityStateZip } = parentAddress
    if (type === 'parent') {
      urlParts = `${
        address1
          ? address1
              .trim()
              .replace(/\s/g, '+')
              .concat('+')
          : ''
      }${
        address2 && !address2.match(/ATTN/gi)
          ? address2
              .trim()
              .replace(/\s/g, '+')
              .concat('+')
          : ''
      }${
        cityStateZip
          ? cityStateZip
              .trim()
              .replace(/,/gi, '')
              .replace(/\s/g, '+')
          : ''
      }`
    } else {
      urlParts = `${
        addressLine1
          ? addressLine1
              .trim()
              .replace(/\s/g, '+')
              .concat('+')
          : ''
      }${
        addressLine2 && !addressLine2.match(/ATTN/gi)
          ? addressLine2
              .trim()
              .replace(/\s/g, '+')
              .concat('+')
          : ''
      }${
        city
          ? city
              .trim()
              .replace(/\s/g, '+')
              .concat('+')
          : ''
      }${state ? state.concat('+') : ''}${zipcode || ''}`
    }
    window.open(`${baseAddress}${urlParts}`, 'external')
  }
}

export function* openGoogleMapsListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { type }
    } = yield take(CONSTANTS.OPEN_GOOGLE_MAPS)

    if (form === formListener) {
      yield fork(openGoogleMapsProcess, form, type)
    }
  }
}

export function* uomGridChangeListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, rowIndex, field, value }
    } = yield take(UPDATE_GRID_CELL_DATA)

    if (form === formListener && field === 'uomId') {
      yield put(
        actions.updateUOMData({
          rowIndex,
          propertyName,
          field,
          value
        })
      )
    }
  }
}

export function* onOpenShipToProcess(response, meta, form) {
  const formState = yield select(state => getIn(state, `ddiForm.${meta.form}`))
  const parentId = getIn(formState, 'values.dataId') // debugger

  yield put(
    masterActions.getEntityAsync.success(
      {
        ...response,
        parentId,
        callLocation: meta.form,
        preNewMode: response.description ? false : true // eslint-disable-line
      },
      {
        form,
        thunk: meta.thunk
      }
    )
  )
}

/*
  manually update (or clear) the
  corresponding Index Search descriptions
*/

const labelUpdates = {
  branchCode: 'branchDescription',
  category: 'categoryDescription',
  ediVendor: 'ediVendorName',
  jobId: 'jobDescription',
  salesmanCode: 'salesmanDescription',
  shipViaCode: 'shipViaDescription',
  taxCode: 'taxCodeDescription',
  territoryCode: 'territoryDescription'
}

export function* clearIndexSearchLabelListener(formListener) {
  const channel = yield actionChannel(DDICONSTANTS.SET_FIELD)
  while (true) {
    const {
      payload: { propertyName, value },
      meta: { form }
    } = yield take(channel)

    if (form === formListener && value === '') {
      if (Object.keys(labelUpdates).includes(propertyName)) {
        const label = labelUpdates[propertyName]
        yield put(
          actions.saveIndexSearchDescription({
            label,
            value: ''
          })
        )
      }
    }
  }
}

export function* updateIndexSearchLabelListener(formListener) {
  while (true) {
    const {
      payload: { exactMatchResults, propertyName },
      meta: { form }
    } = yield take(INDEX_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS)

    if (
      form === formListener &&
      propertyName &&
      exactMatchResults &&
      exactMatchResults.description
    ) {
      if (Object.keys(labelUpdates).includes(propertyName)) {
        const label = labelUpdates[propertyName]
        yield put(
          actions.saveIndexSearchDescription({
            label,
            value: exactMatchResults.description
          })
        )
      }
    }
  }
}

export function* wrapAuditProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getDataId(formState)
  const parentId = getIn(formState, 'values.parent.parentId')
  // const parentType = getIn(formState, 'values.parent.parentType')
  const showAll = getIn(formState, 'fields.showAllAudits.value') || false
  debugger

  try {
    return yield fork(
      listAuditsProcess,
      form,
      {
        dataId,
        parentId,
        // parentType,
        showAll
      },
      true
    )
  } catch (e) {
    throw new Error(e)
  }
}

export default function* sagas(form) {
  yield fork(updateIndexSearchLabelListener, form)
  yield fork(clearIndexSearchLabelListener, form)
  // yield fork(onTabChangeListener, form)
  yield fork(entitySuccessListener, form)
  yield fork(editableGridSagas, form)
  yield fork(cancelEditListener, form)
  yield fork(createShipToListener, form)
  yield fork(saveShipToListener, form)
  // yield fork(generateShipToIdListener, form)
  yield fork(getProductUOMListener, form)
  yield fork(deleteShipToListener, form)
  yield fork(openGoogleMapsListener, form)
  yield fork(uomGridChangeListener, form)
}
