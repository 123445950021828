import React from 'react'
import PropTypes from 'prop-types'
import { DDISelectField, DDITextField } from 'ddiForm/wrapped'
import { emailAddress, required, maxLength } from 'ddiForm/validations'

import { IconButton, Icon } from '@material-ui/core'
import data from 'pages/CustomerMaster/data'
import DDICardWrapper from 'components/DDICardWrapper'

const NameAndAddress = ({
  addressLine1,
  disableInputMask,
  openGoogleMaps,
  pageStyles,
  isEditing
}) => (
  <div>
    <DDICardWrapper title="General Info" contentStyle={{ padding: 10 }}>
      <div style={pageStyles.twoColFlex.wrapper}>
        <div style={pageStyles.twoColFlex.left}>
          <DDITextField
            propertyName="description"
            placeholder="Name"
            margin="dense"
            label="Name"
            fullWidth
            style={{ width: '100%' }}
            validate={[required, maxLength(30)]}
          />
        </div>
        <div style={pageStyles.twoColFlex.right}>
          <DDITextField
            propertyName="attention"
            placeholder="Attention"
            margin="dense"
            label="Attention"
            fullWidth
            style={{ width: '100%' }}
          />
        </div>
      </div>
    </DDICardWrapper>

    <DDICardWrapper title="Ship To Address" contentStyle={{ padding: 10 }}>
      <div style={pageStyles.twoColFlex.wrapper}>
        <div style={pageStyles.twoColFlex.left}>
          <div style={{ position: 'relative' }}>
            <DDITextField
              propertyName="addressLine1"
              placeholder="Address"
              margin="dense"
              label="Address"
              fullWidth
              style={{ width: '100%' }}
              validate={maxLength(30)}
            />
            {addressLine1 && !isEditing ? (
              <IconButton
                style={{
                  bottom: 0,
                  padding: 5,
                  position: 'absolute',
                  right: -5
                }}
                onClick={() => openGoogleMaps('main')}
              >
                <Icon style={{ fontSize: 16 }}>open_in_new</Icon>
              </IconButton>
            ) : null}
          </div>
        </div>
        <div style={pageStyles.twoColFlex.right}>
          <DDITextField
            propertyName="addressLine2"
            placeholder="Address Line 2"
            margin="dense"
            label="Address Line 2"
            fullWidth
            style={{ width: '100%' }}
            validate={maxLength(30)}
          />
        </div>
      </div>
      <div style={pageStyles.twoColFlex.wrapper}>
        <div style={pageStyles.twoColFlex.left}>
          <DDITextField
            propertyName="addressLine3"
            placeholder="Address Line 3"
            margin="dense"
            label="Address Line 3"
            fullWidth
            style={{ width: '100%' }}
            validate={maxLength(30)}
          />
        </div>
        <div style={pageStyles.twoColFlex.right}>
          <DDITextField
            propertyName="city"
            placeholder="City"
            margin="dense"
            label="City"
            fullWidth
            style={{ width: '100%' }}
            validate={maxLength(20)}
          />
        </div>
      </div>
      <div style={pageStyles.twoColFlex.wrapper}>
        <div style={pageStyles.twoColFlex.left}>
          <div
            className="twocol-state-zip"
            style={{
              alignItems: 'stretch',
              display: 'flex',
              maxwidth: '100%'
            }}
          >
            <div
              style={{
                flex: 1,
                margin: '0 10px 0 0',
                order: 1,
                width: '50%'
              }}
            >
              <DDISelectField
                propertyName="state"
                fullWidth
                values={data}
                margin="dense"
                selectValue="value"
                selectDescription="primaryText"
                label="State"
                style={{ width: '100%' }}
                // style={styles.inputText}
              />
            </div>
            <div
              style={{
                flex: 1,
                margin: '0 0 0 10px',
                order: 2,
                width: '50%'
              }}
            >
              <DDITextField
                propertyName="zipcode"
                label="Zip Code"
                placeholder="Zip Code"
                // fullWidth
                style={{ width: '100%' }}
                margin="dense"
                disableMask={disableInputMask}
                mask="zipCode"
                inputProps={{
                  guide: false
                }}
              />
            </div>
          </div>
        </div>
        <div style={pageStyles.twoColFlex.right}>
          <DDITextField
            propertyName="country"
            placeholder="Country"
            margin="dense"
            label="Country"
            fullWidth
            style={{ width: '100%' }}
            wrapperStyle={{
              clear: 'both',
              display: 'flex',
              position: 'relative'
            }}
          />
        </div>
      </div>
    </DDICardWrapper>
  </div>
)

NameAndAddress.propTypes = {
  addressLine1: PropTypes.string.isRequired,
  disableInputMask: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  openGoogleMaps: PropTypes.func.isRequired,
  pageStyles: PropTypes.object.isRequired
}

export default NameAndAddress
