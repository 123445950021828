/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import { withContext } from 'ddiForm'
import { DDIGrid } from 'ddiForm/wrapped'
import { plainDeepEqual, emptyList, getField, getIn } from 'utils'
import withDimensions from 'hoc/withDimensions'
// import * as custMasterActions from 'pages/CustomerMaster/actions'
import IndexSearchCell from './IndexSearchCell'
import ClearTruckRoutesGridCell from './ClearTruckRoutesGridCell'

import * as actions from './actions'
import columnDefs from './columnDefs'

const KEY_LEFT = 37
const KEY_UP = 38
const KEY_RIGHT = 39
const KEY_DOWN = 40

function tabToNextCell(params) {
  console.log(params)
  const previousCell = params.previousCellPosition
  const nextCell = params.nextCellPosition
  const lastRowIndex = previousCell.rowIndex
  let nextRowIndex = params.backwards ? lastRowIndex + 1 : lastRowIndex - 1

  this.gridApi.setFocusedCell(nextCell.rowIndex, nextCell.column.colId)

  const renderedRowCount = this.gridApi.getModel().getRowCount()

  if (nextRowIndex < 0) {
    nextRowIndex = 0
  }
  if (nextRowIndex >= renderedRowCount) {
    nextRowIndex = renderedRowCount - 1
  }

  // const result = {
  //   rowIndex: nextRowIndex,
  //   column: previousCell.column,
  //   floating: previousCell.floating
  // }

  const result = {
    rowIndex: nextCell.rowIndex,
    column: nextCell.column,
    floating: nextCell.floating
  }

  return result
}

function navigateToNextCell(params) {
  console.log(params)
  const previousCell = params.previousCellPosition
  const suggestedNextCell = params.nextCellPosition
  let nextRowIndex
  let renderedRowCount
  switch (params.key) {
    case KEY_DOWN: {
      // return the cell above
      nextRowIndex = previousCell.rowIndex - 1
      if (nextRowIndex < 0) {
        // returning null means don't navigate
        return null
      }
      return {
        rowIndex: nextRowIndex,
        column: previousCell.column,
        floating: previousCell.floating
      }
    }
    case KEY_UP:
      // return the cell below
      nextRowIndex = previousCell.rowIndex + 1
      renderedRowCount = this.gridApi.getModel().getRowCount()
      if (nextRowIndex >= renderedRowCount) {
        // returning null means don't navigate
        return null
      }
      return {
        rowIndex: nextRowIndex,
        column: previousCell.column,
        floating: previousCell.floating
      }

    case KEY_LEFT:
    case KEY_RIGHT:
      return suggestedNextCell
    default:
      return null
    // throw 'this will never happen, navigation is always on of the 4 keys above'
  }
}

const padString = (s, width, char) => {
  if (typeof s !== 'string' || !s) {
    return s
  }

  if (s.length === width) {
    return s
  }
  if (s.length < width) {
    return (new Array(width).join(char) + s).slice(-width)
  }
  if (s.length > width) {
    return s.substring(0, width)
  }

  return s
}

class TruckRoutes extends Component {
  constructor(...args) {
    super(...args)
    this.tabToNextCell = tabToNextCell.bind(this)
    this.navigateToNextCell = navigateToNextCell.bind(this)
  }

  state = { height: -1, rowIndex: null }

  // componentDidUpdate(prevProps, prevState) {
  //   if (!plainDeepEqual(prevProps.routes, this.props.routes)) {
  //     setTimeout(() => {
  //       this.gridApi.setRowData(this.props.routes)
  //       this.gridApi.redrawRows()
  //     }, 0)
  //   }
  // }

  onChange = (rowIndex, newData) => {
    this.props.dispatch(
      actions.truckRoutesChange(rowIndex, newData, this.props.form)
    )

    const getAllColumns = this.columnApi.getAllColumns()
    const colKey = getAllColumns.reduce((acc, next) => {
      if (next.colDef.field === 'stopNumber') {
        acc = acc.concat(next.colId)
      }
      return acc
    }, '')

    setTimeout(
      () =>
        this.gridApi.startEditingCell({
          rowIndex,
          colKey
        }),
      500
    )
  }

  stopChange = params => {
    // const type = typeof params.newValue
    const toThreeChars = padString(params.newValue, 3, '0')
    // console.log(type, trip)
    // debugger
    this.props.dispatch(
      actions.stopNumberChange(
        params.node.rowIndex,
        toThreeChars,
        this.props.form
      )
    )
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.columnApi = params.columnApi
    const columns = params.columnApi.getAllDisplayedVirtualColumns()
    const colIds = columns.map(column => column.colId)

    let tabIndex = 0
    columns.forEach(column => {
      // for each column set a tabindex, otherwise it wont be able to get focus
      const element = document.querySelector(
        `div[col-id=${column.colId}] div.ag-header-cell-label`
      )
      element.setAttribute('tabindex', tabIndex++)

      // register a listener for when a key is pressed on a column
      element.addEventListener('keydown', e => {
        // debugger
        // if a tab, navigate to the next column and focus on it
        // we loop back to the first column if the user is at the last visible column
        if (e.key === 'Tab') {
          let index = colIds.findIndex(colId => colId === column.colId)
          if (index === -1 || index === colIds.length - 1) {
            index = 0
          } else {
            index += 1
          }

          const nextElement = document.querySelector(
            `div[col-id=${colIds[index]}] div.ag-header-cell-label`
          )
          nextElement.focus()

          // otherwise it'll leap forward two columns
          e.preventDefault()
        } else if (e.key === 'Enter') {
          // on enter sort the column
          // you'll probably want to cycle through asc/desc/none here for each enter pressed
          const sort = [{ colId: column.colId, sort: 'asc' }]
          params.api.setSortModel(sort)
        }
      })
    })
  }

  getRowNodeId = data => data.dayOfWeek

  getColumnDefs = props => {
    console.log('truckRoutesColDefs', props)
    const params = {
      focusedCell: props.focusedCell,
      form: props.form,
      isEditing: props.isEditing,
      onChange: props.onCellChange,
      propertyName: props.propertyName,
      indexSearchType: 'shipVia'
    }

    const ret = [
      {
        field: 'dayOfWeek',
        headerName: 'Day Of Week',
        sortable: false
      },
      {
        field: 'shipViaId',
        headerName: 'Ship Via',
        cellRendererParams: params,
        cellRendererFramework: IndexSearchCell,
        sortable: false
      },
      {
        editable: false,
        field: 'description',
        headerName: 'Description',
        sortable: false
      },
      {
        field: 'stopNumber',
        headerName: 'Stop Number',
        editable(prms) {
          if (prms.data.shipViaId && props.isEditing) {
            return true
          }
          return false
        },
        onCellValueChanged(...args) {
          props.stopChange(...args)
        },
        sortable: false
      }
    ]

    if (props.isEditing) {
      ret.push({
        field: 'Clear',
        headerName: '',
        cellRendererFramework: ClearTruckRoutesGridCell,
        form: props.form,
        type: 'truckRoutes',
        width: 50,
        sortable: false
      })
    }

    console.log('truckRoutesColDefs', ret)
    return ret
  }

  render() {
    const gridHeight =
      this.props.form === 'customerMaster' && this.props.height
        ? this.props.height
        : 400

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '0.1 1 80rem',
          margin: '0 auto'
        }}
      >
        <div
          style={{
            flex: '1 1',
            padding: '0 0.5rem'
          }}
        >
          <DDIGrid
            // rowData={routes}
            propertyName="routes"
            title="Truck Routes"
            height={gridHeight}
            columnDefs={this.getColumnDefs({
              propertyName: 'routes',
              form: this.props.form,
              onCellChange: this.onChange,
              stopChange: this.stopChange,
              isEditing: this.props.isEditing
            })}
            headerStyle={{
              background: '#e1e3e4',
              color: '#444',
              fontSize: 13,
              fontWeight: 400,
              lineHeight: '17px',
              margin: 0,
              padding: '5px 0',
              textAlign: 'center',
              width: '100%'
            }}
            getRowNodeId={this.getRowNodeId}
            onGridReady={this.onGridReady}
            // suppressTabbing
            // tabToNextCell={this.tabToNextCell}
            navigateToNextCell={this.navigateToNextCell}
            singleClickEdit
            ref={el => (this.truckRoutesGrid = el)}
            skipValidationOnUnmount
            isEditing={this.props.isEditing}
            // immutableData={false}
          />
        </div>
      </div>
    )
  }
}

const contextObj = {
  isEditing: form => getIn(form, 'isEditing') || false
  // Not used anymore? - LL 9/28/20
  // routes: form => getField(form, 'routes', emptyList, true)
}

const truckRoutesWithContext = withContext(contextObj)(TruckRoutes)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  truckRoutesWithContext
)
