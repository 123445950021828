import { getIn, makeRowDataUnique, setIn } from 'utils'
import { fromJS } from 'immutable'
import * as CONSTANTS from './constants'

const auditBehaviors = {
  [CONSTANTS.LIST_AUDITS.SUCCESS]: (
    state,
    { payload }
  ) => {
    let result = state
    const { additionalDataAvailable, audits } = payload.audit
    debugger

    const additionalDataFlagSet = getIn(result, 'values.additionalDataFlagSet') ?
      getIn(result, 'values.additionalDataFlagSet') : false

    const uniqueAudits = makeRowDataUnique(audits, 'dataId')

    result = setIn(result, 'fields.audits.value', fromJS(uniqueAudits))
    result = setIn(result, 'fields.audits.rowData', fromJS(uniqueAudits))
    result = setIn(result, 'fields.audits.grid', true)

    if (!additionalDataFlagSet && additionalDataAvailable) {
      result = setIn(result, 'fields.audits.additionalDataFlagSet', true)
      result = setIn(
        result,
        'fields.showAllAudits.value',
        !additionalDataAvailable
      )
    }

    // result = setIn(result, 'values.dataId', payload.dataId)
    // result = setIn(result, 'fields.dataId.value', payload.dataId)
    result = setIn(result, 'hasRecord', true)
    result = setIn(result, 'values.additionalDataAvailable', additionalDataAvailable)
    result = setIn(result, 'values.description', payload.description)

    for (const prop in payload) {
      if (prop !== 'audit') {
        result = setIn(result, `values.${prop}`, fromJS(payload[prop]))
      }
    }

    return result
  },
  [CONSTANTS.LIST_AUDITS.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'fetchingEntity', false)
    result = setIn(result, 'fields.audits.value', fromJS([]))
    result = setIn(result, 'fields.audits.rowData', fromJS([]))
    result = setIn(result, 'fields.audits.grid', true)

    return result
  }
}

export default auditBehaviors
