/* eslint no-useless-escape: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import Grid from 'grid'
import { deepEqual, plainDeepEqual } from 'utils'
import memoize from 'memoize-one'
import CheckboxCellRenderer from './CheckboxCellRenderer'
import CheckboxCellEditor from './CheckboxCellEditor'
import ContactsCellEditor from './ContactsCellEditor'
import ContactsCellRenderer from './ContactsCellRenderer'
import RegexCellRenderer from './RegexCellRenderer'
import RegexCellEditor from './RegexCellEditor'
import DeleteCell from './DeleteCell'
import IndexSearchCell from './IndexSearchCell'
import FaxCellRenderer from './FaxCellRenderer'
import FaxCellEditor from './FaxCellEditor'
import CoverPageRenderer from './CoverPageRenderer'
import './styles/css-mod-ignore.scss'

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const contactRegex = /(.*?)/

export default class SendDocumentDropDown extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    console.log(this, nextProps, prevState)
    let ret = null
    if (
      nextProps.showSendInfoResults !== prevState.showSendInfoResults &&
      nextProps.showSendInfoResults != null
    ) {
      ret = {
        showSendInfoResults: nextProps.showSendInfoResults
      }
    }
    console.log(ret)
    return ret
  }

  constructor(props) {
    super(props)
    console.log('dropDownComponent', props, this)
    this.state = {
      showSendInfoResults: true
    }
  }

  getRowNodeIdSearch = data => {
    const ret = data.recordName
    console.log(ret)
    return ret
  }

  getRowNodeIdSendTo = data => {
    data = data.data ? data.data : data
    // const ret = `${data.description}-${data.email}`
    const ret = data.sendDocId
      ? data.sendDocId
      : `${data.description}-${data.email}`
    return ret
  }

  onCellFocused = params => {
    if (
      !params.column ||
      (params.column && params.column.colDef && !params.column.colDef.editable)
    ) {
      return
    }
    if (params?.column?.colId) {
      params.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.column && params.column.colId
        // rowPinned: pinned,
        // keyPress: key,
        // charPress: char
      })
    }
  }

  onGridReady = params => {
    console.log('onGridReady', params)

    if (this.props.onGridReady) {
      this.props.onGridReady(params)
    }

    // this.gridApi = params.api
    params.api.setFocusedCell(0, 'companyId')
    // this.api
  }

  suppressKeyboardEvent = params => {
    const key = event.keyCode
    const isIndexSearchField = params.colDef.field === 'companyId'

    if (key === 9 && isIndexSearchField) {
      return true
    }

    return false
  }

  postProcessPopup = params => {
    const ePopupParent = params?.ePopup?.parentNode
    if (params?.type && params.type === 'ag-list' && ePopupParent) {
      ePopupParent.style.zIndex = 9999
      ePopupParent.style.position = 'absolute'
      ePopupParent.style.top = 0
      ePopupParent.style.left = 0
      ePopupParent.classList.add('ag-popup-show-dropdown')
    }
  }

  /*
  this empty function was overwriting the onCellFocused function up there ^^
  onCellFocused = params => {
    console.log('onCellFocused', params)
  }
  */

  showGrid = memoize((showSendInfoResults, rowData, sendType) => {
    console.log(rowData, showSendInfoResults, sendType)

    return showSendInfoResults ? (
      <div className="send-document-dropdown-grid-wrapper">
        <Grid
          id="sendToGrid"
          key="sendToGrid"
          {...this.props}
          rowData={rowData}
          columnDefs={this.sendToColumnDefs(sendType)}
          getRowNodeId={this.getRowNodeIdSendTo}
          singleClickEdit
          onGridReady={this.onGridReady}
          onCellFocused={this.onCellFocused}
          suppressKeyboardEvent={this.suppressKeyboardEvent}
          postProcessPopup={this.postProcessPopup}
          // onCellFocused={this.onCellFocused}
        />
      </div>
    ) : (
      <div className="send-document-dropdown-grid-wrapper">
        <Grid
          id="searchGrid"
          key="searchGrid"
          {...this.props}
          rowData={rowData}
          getRowNodeId={this.getRowNodeIdSearch}
          suppressKeyboardEvent={this.suppressKeyboardEvent}
          postProcessPopup={this.postProcessPopup}
          // onCellFocused={this.onCellFocused}
        />
      </div>
    )
  })

  sendToColumnDefs = memoize((sendType = 'Email') => [
    {
      field: 'companySearchType',
      headerName: 'Type',
      width: 90,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ['Customer', 'Vendor', 'Free Form'] },
      refData: {
        Customer: 'Customer',
        Vendor: 'Vendor',
        'Free Form': 'Free Form'
        // "nss": "Nissan"
      },
      onCellValueChanged: params => {
        // console.log(...rest)
        if (deepEqual(params.newValue, params.oldValue)) return
        this.props.onChange('companySearchType', params.newValue, params.node)
      },
      sortable: false
    },
    {
      colId: 'companyId',
      field: 'companyId',
      headerName: 'Company',
      editable: true,
      cellEditorFramework: IndexSearchCell,
      cellEditorParams: params => {
        console.log('cellEditorParams', params)
        const indexSearchType =
          params.data.companySearchType &&
          params.data.companySearchType !== 'Free Form'
            ? params.data.companySearchType
            : ''
        return {
          indexSearchType,
          dispatch: this.props.dispatch,
          onChange: (val, node) => {
            console.log(this.props, val, this.state)
            this.props.onChange('companyId', val, node)
          }
        }
      },
      sortable: false,
      valueGetter(params) {
        return params.data.companyId
      },
      valueSetter(params) {
        return params.newValue
      },
      suppressKeyboardEvent(params) {
        console.log(params, params.event.keyCode)
        return true
      }
      // indexSEARCH FUCKKKKK
    },
    {
      editable: true,
      colId: 'description',
      field: 'description',
      headerName: 'Name',
      cellEditorFramework: ContactsCellEditor,
      cellRendererFramework: ContactsCellRenderer,
      cellRendererParams: {
        regex: contactRegex
      },
      cellEditorParams: {
        onChange: this.props.onChange,
        field: 'description'
      },
      sortable: false,
      valueGetter: params => {
        console.log(params)
        // return 'valu get'
        return params.data.description || ''
      },
      valueSetter: params => {
        console.log(params)
        return params.newValue || params.oldValue
      },
      valueParser: params => {
        console.log(params)
        return 'ummm'
      },
      valueFormatter: params => {
        console.log(params)
        return params.data.description
        // return 'foobarred beitch'
      }
    },
    {
      editable: true,
      colId: 'email',
      field: 'email',
      headerName: 'Email Address',
      cellRendererFramework: RegexCellRenderer,
      cellRendererParams: {
        regex: emailRegex
      },
      cellEditorFramework: RegexCellEditor,
      cellEditorParams: {
        // onChange: this.sendToChange,
        onChange: this.props.onChange,
        field: 'email',
        regex: emailRegex
      },
      hide: sendType !== 'Email',
      sortable: false
    },
    {
      colId: 'fax',
      field: 'fax',
      headerName: 'Fax',
      hide: sendType === 'Email',
      cellRendererFramework: FaxCellRenderer,
      editable: true,
      cellEditorFramework: FaxCellEditor,
      cellEditorParams: {
        field: 'fax',
        onChange: this.props.onChange
      },
      sortable: false
    },
    {
      colId: 'freeForm',
      field: 'freeForm',
      headerName: 'Free Form',
      hide: sendType === 'Email',
      cellRendererFramework: CheckboxCellRenderer,
      cellEditorFramework: CheckboxCellEditor,
      editable: true,
      width: 100,
      cellEditorParams: {
        onChange: this.props.onChange,
        field: 'freeForm'
      },
      sortable: false
    },
    {
      field: 'includeAreaCode',
      headerName: 'Area Code',
      hide: sendType === 'Email',
      cellRendererFramework: CheckboxCellRenderer,
      cellEditorFramework: CheckboxCellEditor,
      editable: true,
      width: 100,
      cellEditorParams: {
        onChange: this.props.onChange,
        field: 'includeAreaCode'
      },
      sortable: false
    },
    {
      field: 'includeOne',
      headerName: 'Include 1',
      hide: sendType === 'Email',
      cellRendererFramework: CheckboxCellRenderer,
      cellEditorFramework: CheckboxCellEditor,
      editable: true,
      width: 100,
      cellEditorParams: {
        onChange: this.props.onChange,
        field: 'includeOne'
      },
      sortable: false
    },
    {
      field: 'coverPageId',
      headerName: 'Cover Page',
      hide: sendType === 'Email',
      width: 100,
      cellRendererFramework: CoverPageRenderer,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ['Default', 'None'] },
      onCellValueChanged: params => {
        // console.log(...rest)
        console.log(params)
        this.props.onChange(
          'coverPageId',
          params.newValue === 'Default' ? 'Default.cov' : 'None',
          params.node
        )
      },
      sortable: false
    },
    {
      cellRendererFramework: DeleteCell,
      cellRendererParams: {
        onClick: this.props.deleteRow
      },
      headerName: 'X',
      width: 50,
      sortable: false
    }
  ])

  render() {
    console.log(this.props, this.state)
    return this.showGrid(
      this.state.showSendInfoResults,
      this.props.rowData,
      this.props.sendType
    )
  }
}
