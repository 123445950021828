import React from 'react'
import PropTypes from 'prop-types'
import { DDIIndexSearch, DDITextField } from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import { Field, Label } from 'ddiForm'

const ShipToDefaults = ({ pageStyles }) => (
  <DDICardWrapper title="Ship To Defaults" contentStyle={{ padding: 10 }}>
    <div style={pageStyles.searchRow.wrapper}>
      <label htmlFor="branch" style={pageStyles.searchRow.label}>
        Branch
      </label>
      <div style={pageStyles.searchRow.input}>
        <DDIIndexSearch
          propertyName="branchCode"
          id="branchCode"
          searchType="branch"
          metaKey="branchCode"
          fullWidth
          minWidth="100%"
        />
      </div>
      <div style={pageStyles.searchRow.description}>
        <Field
          component={Label}
          propertyName="branchDescription"
          labelStyle={{ width: '100%' }}
        />
      </div>
    </div>

    <div style={pageStyles.searchRow.wrapper}>
      <label htmlFor="salesman" style={pageStyles.searchRow.label}>
        Salesman
      </label>
      <div style={pageStyles.searchRow.input}>
        <DDIIndexSearch
          propertyName="salesmanCode"
          id="salesmanCode"
          searchType="salesman"
          metaKey="salesmanCode"
          fullWidth
          minWidth="100%"
        />
      </div>
      <div style={pageStyles.searchRow.description}>
        <Field
          component={Label}
          propertyName="salesmanDescription"
          labelStyle={{ width: '100%' }}
        />
      </div>
    </div>

    <div style={pageStyles.searchRow.wrapper}>
      <label htmlFor="category" style={pageStyles.searchRow.label}>
        Category
      </label>
      <div style={pageStyles.searchRow.input}>
        <DDIIndexSearch
          propertyName="category"
          id="category"
          searchType="custCategory"
          metaKey="category"
          fullWidth
          minWidth="100%"
        />
      </div>
      <div style={pageStyles.searchRow.description}>
        <Field
          component={Label}
          propertyName="categoryDescription"
          labelStyle={{ width: '100%' }}
        />
      </div>
    </div>

    <div style={pageStyles.searchRow.wrapper}>
      <label htmlFor="territory" style={pageStyles.searchRow.label}>
        Territory
      </label>
      <div style={pageStyles.searchRow.input}>
        <DDIIndexSearch
          propertyName="territoryCode"
          id="territory"
          searchType="territory"
          metaKey="territoryCode"
          fullWidth
          minWidth="100%"
        />
      </div>
      <div style={pageStyles.searchRow.description}>
        <Field
          component={Label}
          propertyName="territoryDescription"
          labelStyle={{ width: '100%' }}
        />
      </div>
    </div>

    <div style={pageStyles.searchRow.wrapper}>
      <label htmlFor="shipViaCode" style={pageStyles.searchRow.label}>
        Ship Via
      </label>
      <div style={pageStyles.searchRow.input}>
        <DDIIndexSearch
          propertyName="shipViaCode"
          id="shipViaCode"
          searchType="shipVia"
          metaKey="shipViaCode"
          fullWidth
          minWidth="100%"
        />
      </div>
      <div style={pageStyles.searchRow.description}>
        <Field
          component={Label}
          propertyName="shipViaDescription"
          labelStyle={{ width: '100%' }}
        />
      </div>
    </div>

    <div style={pageStyles.searchRow.wrapper}>
      <label htmlFor="jobId" style={pageStyles.searchRow.label}>
        Job ID
      </label>
      <div style={pageStyles.searchRow.input}>
        <DDIIndexSearch
          propertyName="jobId"
          id="jobId"
          searchType="job"
          metaKey="jobId"
          fullWidth
          minWidth="100%"
        />
      </div>
      <div style={pageStyles.searchRow.description}>
        <Field
          component={Label}
          propertyName="jobDescription"
          labelStyle={{ width: '100%' }}
        />
      </div>
    </div>

    <div style={pageStyles.searchRow.wrapper}>
      <label htmlFor="customerPO" style={pageStyles.searchRow.label}>
        Customer P/O
      </label>
      <div style={pageStyles.searchRow.input}>
        <DDITextField
          propertyName="customerPO"
          margin="dense"
          fullWidth
          style={{ width: '100%' }}
        />
      </div>
    </div>
  </DDICardWrapper>
)

ShipToDefaults.propTypes = {
  pageStyles: PropTypes.object.isRequired
}

export default ShipToDefaults
