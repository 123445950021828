import numToDollar from 'utils/numToDollar'

const formatDollarFields = (params, decimalScale = 2) => {
  if (params.value || params.value === 0) {
    return numToDollar(params.value, 'en-US', 'USD', decimalScale, 10)
  }
  return null
}

export default formatDollarFields
