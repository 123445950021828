import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { getValue } from 'utils'
// import { createNewProduct } from 'pages/ProductMaster/actions'

const marginRight = { marginRight: 5 }

export const RightFooterButtons = ({
  hasRecord,
  isEditing,
  newMode,
  preNewMode,
  templateKey
}) => {
  const edit = useRef(null)
  const cancel = useRef(null)
  const save = useRef(null)

  useEffect(() => {
    if (hasRecord && !isEditing && edit.current) {
      edit.current.focus()
    }
  }, [hasRecord, isEditing])

  useEffect(() => {
    if (hasRecord && isEditing && save.current) {
      save.current.focus()
    }
  }, [hasRecord, isEditing])

  return (
    <>
      {preNewMode && !hasRecord && (
        <DDIButton
          variant="contained"
          label="Ok"
          actionName="addNew"
          preventAutoDisable
          tabIndex={0}
          style={marginRight}
        />
      )}
      {!preNewMode && (
        <DDIButton
          variant="contained"
          label="New"
          actionName="addNew"
          preventAutoDisable
          tabIndex={0}
          style={marginRight}
        />
      )}
      {hasRecord && (isEditing || newMode) && (
        <DDIButton
          variant="contained"
          label="Save"
          actionName="save"
          preventAutoDisable
          tabIndex={0}
          buttonRef={save}
          style={marginRight}
        />
      )}
      {hasRecord && isEditing && !newMode && (
        <DDIButton
          variant="contained"
          label="Delete"
          actionName={templateKey ? 'deleteTemplate' : 'delete'}
          preventAutoDisable
          tabIndex={0}
          style={marginRight}
        />
      )}
      {hasRecord && !isEditing && !newMode && (
        <DDIButton
          variant="contained"
          label="Edit"
          actionName={templateKey ? 'lockTemplate' : 'lockForEdit'}
          preventAutoDisable
          tabIndex={0}
          buttonRef={edit}
          // autoFocus
          style={marginRight}
        />
      )}
      {(preNewMode || newMode || isEditing) && (
        <DDIButton
          variant="contained"
          label="Cancel"
          actionName={
            templateKey
              ? 'unlockTemplate'
              : preNewMode && !isEditing
              ? 'cancelPreNewMode'
              : 'cancelEdit'
          }
          preventAutoDisable
          tabIndex={0}
          style={marginRight}
          buttonRef={cancel}
        />
      )}
      <DDIButton
        variant="contained"
        label="Exit"
        actionName={templateKey && isEditing ? 'exitTemplate' : 'exit'}
        preventAutoDisable
        tabIndex={0}
        style={marginRight}
      />
    </>
  )
}

RightFooterButtons.propTypes = {
  hasRecord: PropTypes.bool,
  isEditing: PropTypes.bool,
  newMode: PropTypes.bool,
  preNewMode: PropTypes.bool
}

RightFooterButtons.defaultProps = {
  hasRecord: false,
  isEditing: false,
  newMode: false,
  preNewMode: false
}

export const contextObj = {
  newMasterRecord: formState => getValue(formState, 'newMasterRecord', false),
  newMode: form => form.get('newMode'),
  preNewMode: form => form.get('preNewMode'),
  templateKey: form => getValue(form, 'templateKey', ''),
  hasRecord: form => form.get('hasRecord'),
  isEditing: form => form.get('isEditing')
}

export default withContext(contextObj)(RightFooterButtons)
