import React, { Component, Fragment } from 'react'
import Grid from 'grid'
import {
  Input,
  InputAdornment,
  IconButton,
  Icon,
  Popover
} from '@material-ui/core'

const columnDefs = [
  { field: 'description', headerName: 'Name' },
  { field: 'jobTitle', headerName: 'Title' },
  { field: 'fax', headerName: 'Fax' },
  { field: 'email', headerName: 'Email' },
  { field: 'defaultSendToDisplay', headerName: 'Def Send To' }
]
export default class ContactsCellEditor extends Component {
  constructor(...args) {
    super(...args)
    // debugger
    this.state = {
      open: false,
      rowData: this.props.node.data.contacts,
      value: this.props.node.data.description || ''
    }
  }

  componentDidMount() {
    if (this.input) {
      this.input.select()
    }
  }

  componentWillUnmount() {
    if (this.state.isDirty) {
      if (this.props.onChange) {
        this.props.onChange(this.props.field, this.state.value, this.props.node)
      }
    }
  }

  onChange = e => {
    e.persist()
    const { value } = e.target
    if (this.props.data.companySearchType === 'Customer') {
      const regex = new RegExp(value, 'gi')
      let open = false
      if (value.length >= 5) {
        open = this.props.data.contacts.filter(x => x.description.match(regex))
          .length
        open = Boolean(open)
      }

      this.setState({
        value,
        open,
        rowData: open
          ? this.props.data.contacts.filter(x => x.description.match(regex))
          : this.props.data.contacts,
        isDirty: true
      })
    } else {
      this.setState({
        value,
        isDirty: true
      })
    }
  }

  // () => {
  //   this.props.onChange(this.props.field, value, this.props.node)
  // }
  onClick = () => this.setState({ open: !this.state.open })

  onClose = () =>
    this.setState({ open: false, rowData: this.props.data.contacts })

  onFocus = () => this.input.select()

  onRowClicked = e => {
    // console.log(e)
    this.setState(
      {
        value: e.data.description,
        open: false
      },
      () => {
        // console.log(this, this.state)
        // debugger
        this.props.onChange(
          this.props.field,
          e.data.description,
          this.props.node
        )
        // debugger
        // this.props.node.setDataValue('description', e.d)
        // this.props.onChange('contact', e.data.description, e)
      }
    )
    // debugger
    // console.log(this.props)
  }

  bindInputRef = c => (this.input = c)

  input = null

  getHeight = () => {
    const { rowData } = this.state
    return rowData && rowData.length ? rowData.length * 27 + 52 : 150
  }

  getValue = p => {
    console.log(p)
    return this.state.value
  }

  getRowNodeId = data => `${data.description}-${data.dataId}`

  refresh = params => {
    console.log(params)
    return false
  }

  onBlur = event => {
    event.persist()
    const { value } = event.target
    if (this.props.data.companySearchType === 'Customer') {
      // const regex = new RegExp(value, 'gi')
      const isCustomer = this.props.data.contacts.find(
        x => x.description === value
      )

      if (!isCustomer) {
        this.setState({ value: '' })
      }
    }
  }

  render() {
    console.log(this.props, this.state)
    return (
      <div style={{ width: '100%', height: 27, position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: 27
          }}
        >
          <Input
            inputRef={this.bindInputRef}
            onFocus={this.onFocus}
            endAdornment={
              this.props.node.data.companySearchType !== 'Free Form' ? (
                <InputAdornment position="end">
                  <IconButton onClick={this.onClick}>
                    <Icon>keyboard_arrow_down</Icon>
                  </IconButton>
                </InputAdornment>
              ) : null
            }
            onChange={this.onChange}
            onBlur={this.onBlur}
            value={this.state.value}
            fullWidth
            style={{ height: 26, padding: 0 }}
            inputProps={{
              style: { height: 26, padding: 0 }
            }}
          />
          <Popover
            anchorEl={this.input}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            onClose={this.onClose}
            open={this.state.open}
            height={this.getHeight()}
          >
            <div style={{ width: 400, height: 300 }}>
              <Grid
                columnDefs={columnDefs}
                onRowClicked={this.onRowClicked}
                rowData={this.state.rowData}
                getRowNodeId={this.getRowNodeId}
              />
            </div>
          </Popover>
        </div>
      </div>
    )
  }
}
