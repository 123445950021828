/* eslint import/prefer-default-export: 0 */
import { call, fork, select, put, take } from 'redux-saga/effects'

import { getIn } from 'utils'
import { api } from 'services'

import { SET_FIELD  } from 'ddiForm/constants'
// import * as CONSTANTS from './constants'
import { getEntityAsync } from 'ddiForm/MasterScreen/actions'
import { getFormSelector, getMapResponse } from 'ddiForm/utils'


import { listAudits } from './actions'

export function* wrapAuditDataAccess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const showAllAudits = getIn(formState, 'fields.showAllAudits.value') || false

  try {
    yield fork(listAuditsProcess, form, { dataId, showAll: showAllAudits }, true)
  } catch (e) {
    // debugger
    throw new Error(e)
  }
}

export function* additionalAuditsDataFlagListener(formListener) {
  while (true) {
    const {
      payload: { propertyName },
      meta: { form }
    } = yield take(SET_FIELD)
    if (form === formListener && propertyName === 'showAllAudits') {
      // debugger
      const apiParams = yield call(getApiParams, form)
      yield call(listAuditsProcess, form, apiParams)
    }
  }
}

export function* getApiParams(form) {
  // const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const formState = yield select(getFormSelector(form))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const showAll = getIn(formState, 'fields.showAllAudits.value') || false
  const guid = getIn(formState, 'guid')
  let apiParams = {}

  if (form === 'contact' || form === 'customerShipTo') {
    const parentId = getIn(formState, 'values.parent.parentId')
    const parentType = getIn(formState, 'values.parent.parentType')
    apiParams = { dataId, parentId, parentType, showAll }
  } else {
    apiParams = { dataId, showAll, guid }
  }

  return apiParams
}

export function* listAuditsProcess(form, apiParams, isAccessAttempt = false) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const { dataId } = apiParams
  let groupNames = getIn(formState, 'masterOptions.trackedTabs')
  // const selectedPrimaryTab = getIn(
  //   formState,
  //   'masterOptions.selectedPrimaryTab'
  // )
  // const selectedSecondaryTab = getIn(
  //   formState,
  //   'masterOptions.selectedSecondaryTab'
  // )

  groupNames = groupNames && groupNames.toJS ? groupNames.toJS() : []
  groupNames = [...groupNames, 'audit']
  // const groupNames = ['audit']

  if (!dataId) {
    return
  }

  yield put(getEntityAsync.request(form))
  // yield put(listAudits(form).request())
  // debugger

  const R = yield call(api.getAudits, {
    ...apiParams,
    groupNames,
    guid,
    form: form.match(/salesOrder/) ? 'salesOrder' : form
  })

  if (R.response) {
    const mapResponse = yield getMapResponse({ formState, tabIds: groupNames })
    const response = mapResponse({
      response: R.response,
      tabIds: groupNames,
      formState,
      groupNames
    })
    yield put(getEntityAsync.success(response, form))
  } else {
    yield put(listAudits.failure(R.error, form))

    // if (isAccessAttempt) {
      // const action = yield take(CHANGE_FORM_TAB)
      // console.log(action)
      // debugger
      // yield put(tryChangeFormTab(form, selectedPrimaryTab, selectedSecondaryTab))
    // throw new Error(error)
    // }
  }
}
