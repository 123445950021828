/* eslint react/sort-comp: 0, react/no-find-dom-node: 0, consistent-return: 0 */
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import { getIn, is, noop, plainDeepEqual, toCamelCase } from 'utils'
import { TextField } from '@material-ui/core'
import IndexSearch from 'components/Search/IndexSearch'
import IndexSearchMobile from 'mobile/components/IndexSearchMobile'
import * as actions from 'components/Search/IndexSearch/actions'
import shortid from 'shortid'
import { getErrorIcon, updateGridCellData } from 'components/EditableGrid/utils'
import {
  resetGridIndexSearch,
  updateGridCellData as updateGridCellDataAction
} from 'components/EditableGrid/actions'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import behaviors from 'components/Search/IndexSearch/behaviors'
import { debounce } from 'lodash'

const generateScreenParams = obj =>
  Object.keys(obj).reduce((acc, next) => {
    if (next === 'fullDescription') {
      acc.name = toCamelCase(obj[next])
      acc.title = obj[next]
    } else if (next === 'imageFileName') {
      acc.image = obj[next]
    } else if (next === 'dataId') {
      acc.dataId = obj[next]
    } else if (next === 'parentId') {
      acc.parentId = obj[next]
    } else {
      acc[next] = obj[next]
    }

    return acc
  }, {})

const mapStateToProps = (state, ownProps) => {
  // debugger
  // const {
  //   colDef: {
  //     cellRendererParams: { linkTo, form, additionalOpenScreenParams = [] }
  //   },
  //   value,
  //   propertyName
  // } = ownProps

  const param = ownProps.colDef.cellRendererParams
    ? 'cellRendererParams'
    : 'cellEditorParams'

  const {
    colDef: {
      [param]: {
        linkTo,
        form,
        additionalOpenScreenParams = [],
        groupNames = ['setup', 'main']
      }
    },
    value,
    propertyName
  } = ownProps

  const formState = getIn(state, `ddiForm.${form}`)
  const rowData = getIn(state, `ddiForm.${form}.fields.${propertyName}.rowData`)
  let indexSearchResultsGrid = getIn(
    formState,
    `fields.${propertyName}.editableGridIndexSearchField.grid`
  )
  indexSearchResultsGrid =
    indexSearchResultsGrid && indexSearchResultsGrid.toJS
      ? indexSearchResultsGrid.toJS()
      : {}

  const indexSearchResultsValue =
    getIn(
      formState,
      `fields.${propertyName}.editableGridIndexSearchField.value`
    ) || ''

  const isSet =
    getIn(
      formState,
      `fields.${propertyName}.editableGridIndexSearchField.isSet`
    ) || false

  let focusedCell = getIn(formState, `fields.${propertyName}.focusedCell`)
  focusedCell = focusedCell ? focusedCell.toJS() : {}

  if (linkTo) {
    let openScreenParams = getIn(state, `auth.screensMap.${linkTo}`)
    openScreenParams = openScreenParams ? openScreenParams.toJS() : {}

    openScreenParams = {
      ...openScreenParams,
      groupNames,
      dataId: value
    }

    /* for opening a screen or record with a parentId */
    if (form && additionalOpenScreenParams) {
      if (additionalOpenScreenParams.includes('parentId')) {
        openScreenParams = {
          ...openScreenParams,
          parentId:
            getIn(state, `ddiForm.${form}.fields.dataId.value`) ||
            getIn(state, `ddiForm.${form}.values.dataId`)
        }
      }

      if (
        additionalOpenScreenParams.includes('productAnalysis') ||
        linkTo === 'productAnalysis'
      ) {
        openScreenParams = {
          activeKey: 'analysis',
          image: 'inventory_product_analysis_16.png',
          route: 'productmaster',
          title: 'Product Analysis',
          groupNames: ['analysis', 'overview'],
          dataId: value,
          name: 'productMaster',
          startTab: {
            primary: 'analysis',
            secondary: 'overview'
          }
        }
      }

      if (
        additionalOpenScreenParams.includes('customerLedger') ||
        linkTo === 'customerLedger'
      ) {
        openScreenParams = {
          image: 'accounting_accounts_receivable_customer_ledger_16.png',
          name: 'customerLedger',
          title: 'Customer Ledger',
          activeKey: 'ledger',
          route: 'customermaster',
          groupNames: ['summary', 'invoices'],
          dataId: value
        }
      }

      if (linkTo === 'salesOrder') {
        openScreenParams = {
          // activeKey: '',
          // image: 'sales_order_entry_16.png',
          // route: 'salesorder',
          // title: 'Sales Order',
          // groupNames: ['header', 'detail'],
          description: 'Sales Order',
          image: 'sales_order_entry_32.png',
          imageFileName16: 'sales_order_entry_16.png',
          name: 'salesOrder',
          newInstance: true,
          read: true,
          route: 'salesorder',
          title: 'Sales Order',
          dataId: value
        }
      }
    }

    return {
      focusedCell,
      indexSearchResultsGrid,
      isSet,
      indexSearchResultsValue,
      rowData: rowData && rowData.toJS ? rowData.toJS() : [],
      openScreenParams: generateScreenParams(openScreenParams)
    }
  }

  return {
    focusedCell,
    indexSearchResultsGrid,
    indexSearchResultsValue,
    isSet
    // rowData: rowData && rowData.toJS ? rowData.toJS() : []
  }
}

class IndexSearchCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    exactMatchSearch: PropTypes.func,
    focusedCell: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    indexSearchType: PropTypes.string,
    indexSearchResultsGrid: PropTypes.object.isRequired,
    indexSearchResultsValue: PropTypes.string.isRequired,
    isSet: PropTypes.bool.isRequired,
    openScreenParams: PropTypes.object,
    meta: PropTypes.object,
    partialMatchSearch: PropTypes.func,
    propertyName: PropTypes.string.isRequired,
    rowData: PropTypes.array.isRequired
  }

  static defaultProps = {
    exactMatchSearch: noop,
    indexSearchType: 'product',
    meta: {
      allowInstantSearch: true,
      minimumKeywordLength: 2,
      allowSearchAll: false
    },
    partialMatchSearch: noop
  }

  _isMounted = false

  constructor(props) {
    super(props)
    // console.log('IndexSearchCell', props)

    this.state = {
      isEditing: false,
      value: this.props.value,
      rowIndex: this.props.rowIndex,
      textFieldVal: this.props.value || ''
    }
    const param = props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    const {
      colDef: {
        [param]: { disabledFn, requiredFn, tooltip, getErrorMessageFn }
      }
    } = props

    if (disabledFn && typeof disabledFn === 'function') {
      this.props.colDef[param].disabledFn = this.props.colDef[
        param
      ].disabledFn.bind(this)
    }

    if (requiredFn && typeof requiredFn === 'function') {
      this.props.colDef[param].requiredFn = this.props.colDef[
        param
      ].requiredFn.bind(this)
    }

    if (getErrorMessageFn && typeof getErrorMessageFn === 'function') {
      this.props.colDef[param].getErrorMessageFn = this.props.colDef[
        param
      ].getErrorMessageFn.bind(this)
    }

    this.updateGridCellData = updateGridCellData.bind(this)

    this.checkFocusOnInput = debounce(this.checkFocusOnInput, 100)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value }
    }

    if (
      prevState.textFieldVal !== '' &&
      nextProps.value === '' &&
      nextProps.value !== prevState.textFieldVal &&
      prevState.isEditing === true
    ) {
      return { isEditing: false }
    }

    /* ^^related to the above, now that we've received a new value,
         we can strip it out because we've previously marked isEditing === false
         AFTER getting the new value prop back after being handled by the saga
         or reducer (as described above)
    */
    if (
      prevState.textFieldVal !== '' &&
      nextProps.value === '' &&
      nextProps.value !== prevState.textFieldVal &&
      prevState.isEditing === false
    ) {
      return { textFieldVal: nextProps.value }
    }

    return null
  }

  componentDidMount() {
    this._isMounted = true
    const param = this.props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'

    const {
      colDef: {
        [param]: { form }
      },
      propertyName,
      isSet,
      indexSearchResultsValue
    } = this.props

    // if (form && propertyName && indexSearchResultsValue) {
    //   this.props.dispatch(resetGridIndexSearch(form, { propertyName }))
    // }

    setTimeout(() => {
      this.checkFocusOnInput()
    }, 100)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      // !plainDeepEqual(prevProps.rowData, this.props.rowData) ||
      !plainDeepEqual(prevProps.focusedCell, this.props.focusedCell)
    ) {
      setTimeout(() => {
        this.checkFocusOnInput()
      }, 100)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  checkFocusOnInput = () => {
    if (!this.props.api || !this._isMounted) return

    const focusedCell = this.props.api.getFocusedCell()
    const {
      colDef: { field },
      data: { rowId = null }
    } = this.props
    // debugger
    const model = this.props.api.getModel()
    if (!model) {
      return
    }

    const sortedRowData = model.rowsToDisplay.reduce((acc, next) => {
      acc = acc.concat(next.data)
      return acc
    }, [])
    const sortedRowIndex = sortedRowData.findIndex(x => x.rowId === rowId)

    if (!focusedCell || sortedRowIndex < 0) {
      return
    }

    if (
      focusedCell &&
      focusedCell.column &&
      focusedCell.column.colDef &&
      focusedCell.column.colDef.field &&
      focusedCell.column.colDef.field === field &&
      focusedCell.rowIndex === sortedRowIndex
    ) {
      // debugger
      if (findDOMNode(this.field) && findDOMNode(this.field) !== null) {
        findDOMNode(this.field).focus()
      }

      if (findDOMNode(this.textField) && findDOMNode(this.textField) !== null) {
        findDOMNode(this.textField).focus()
      }
    }
  }

  // setField = (val, _, __, result) => {
  //   const {
  //     colDef: {
  //       cellRendererParams: { onChange }
  //     }
  //   } = this.props

  //   if (!result) return
  //   this.setState(
  //     { value: val, textFieldVal: result.name || result.recordName },
  //     () => {
  //       if (this.props.onChange) {
  //         /* this is for cells that do not behave like standard index search cells */
  //         /* i.e. no validation of dataId, no automatic setting of description */
  //         if (onChange && onChange === 'updateGridCellData') {
  //           this.updateGridCellData('textFieldVal')
  //         } else {
  //           // strip out the ridiculous exclamation marks
  //           const sanitizedReuslts = Object.keys(result).reduce((acc, next) => {
  //             acc[next] = result[next] ? result[next].replace('!', '') : ''
  //             return acc
  //           }, {})
  //           this.props.onChange(this.state.rowIndex, sanitizedReuslts)
  //         }
  //       }
  //     }
  //   )
  // }

  setField = (val, _, __, result) => {
    const param = this.props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    const {
      colDef: {
        [param]: { onChange, tabToNextCellOnChange = false },
        field,
        generateDataId
      }
    } = this.props

    if (!result) {
      result = {
        dataId: val,
        description: val,
        displayName: val,
        name: val,
        parentId: '',
        parentType: '',
        recordName: val,
        recordType: '',
        searchType: ''
      }
    }
    this.setState(
      { value: val, textFieldVal: result.name || result.recordName || val },
      () => {
        if (this.props.onChange) {
          // debugger
          /* this is for cells that do not behave like standard index search cells */
          /* i.e. no validation of dataId, no automatic setting of description */
          if (onChange && onChange === 'updateGridCellData') {
            this.updateGridCellData('textFieldVal')

            if (tabToNextCellOnChange && this?.props?.api?.tabToNextCell) {
              this.props.api.tabToNextCell()
            }
          } else if (
            onChange &&
            onChange === 'setIndexSearchValueAndDescription'
          ) {
            this.updateGridCellData('textFieldVal')
            this.props.dispatch(
              updateGridCellDataAction(this.props.form, {
                rowIndex: this.props.rowIndex,
                rowId:
                  this.props.data && this.props.data.rowId
                    ? this.props.data.rowId
                    : '',
                propertyName: this.props.propertyName,
                field: 'description',
                value: result.description,
                generateDataId,
                lastCell: false,
                requiresAdditionalValidation: false
              })
            )
          } else {
            // strip out the ridiculous exclamation marks
            const sanitizedResults = Object.keys(result).reduce((acc, next) => {
              if (result[next] && typeof result[next] === 'string') {
                acc[next] = result[next] ? result[next].replace('!', '') : ''
              } else {
                acc[next] = result[next]
              }
              return acc
            }, {})
            // console.log(this.props.api)

            // debugger
            this.props.onChange(
              {
                ...this.props.data,
                rowIndex: this.props.rowIndex
              },
              sanitizedResults
            )
          }
        }
      }
    )
  }

  exactMatch = args =>
    this.props.dispatch(actions.exactMatchSearch(null, { ...args }))

  partialMatch = args =>
    this.props.dispatch(actions.partialMatchSearch(null, { ...args }))

  inputRef = c => (this.field = c)

  onChange = event => {
    this.setState({ isEditing: true, textFieldVal: event.target.value })
  }

  /* for editing and validating text field input only */
  onBlur = event => {
    this.props.onChange(
      {
        ...this?.props?.data,
        rowIndex: this.props.rowIndex
      },
      {
        recordName: this.state.textFieldVal
      }
    )
  }

  isInputDisabled = () => {
    const param = this.props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    const {
      data,
      colDef: {
        [param]: { disabled, disabledFn }
      }
    } = this.props

    if (disabledFn && typeof disabledFn === 'function') {
      return disabledFn(data)
    }

    /* mainly for text fields like the 'State' field in Selection Critieria modal */
    if (this.props.indexSearchType === 'None' && this.props.value) {
      return true
    }

    if (disabled === false || !this.props.colDef[param].disabled) {
      return false
    }

    return true
  }

  onKeyDown = event => {
    const param = this.props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    const {
      colDef: {
        [param]: { linkTo, form, additionalOpenScreenParams = [] }
      },
      value
    } = this.props

    if (this.isLeftOrRight(event)) {
      event.stopPropagation()
      return false
    }

    // if (event.key === 'Tab') {
    //   console.log(this, this.props)
    //   event.preventDefault()
    //   debugger
    // }

    if (
      event.key === 'Tab' &&
      this.props.indexSearchType &&
      event.target.value &&
      this.props.indexSearchType !== 'None'
    ) {
      /*
        we need to handle tabs on our own now,
        with this new index search
      */
      this.setState({ isEditing: false }, async () => {
        try {
          const p = await this.props.dispatch(
            actions.exactMatchSearch(form, {
              searchType: this.props.indexSearchType,
              indexSearchType: this.props.indexSearchType,
              keyword: event.target.value,
              propertyName: `${this.props.propertyName}.editableGridIndexSearchField`,
              allowInvalidValues:
                this.props.meta && this.props.meta.allowInvalidValues
                  ? this.props.meta.allowInvalidValues
                  : false,
              moreInfo: this.props.colDef?.cellRendererParams?.moreInfo || false
            })
          )

          if (p.partialMatchResults) {
            console.log('partialMatchResults', p.partialMatchResults)
            // debugger
          }

          if (p.exactMatchResults) {
            const val =
              p.exactMatchResults.name || p.exactMatchResults.recordName
            this.setField(val, null, null, p.exactMatchResults)
          }
        } catch (e) {
          console.log(e, event.target.value)
        }
      })
    }
  }

  isLeftOrRight = event => [37, 39].indexOf(event.keyCode) > -1

  openScreen = () => {
    const { value } = this.state
    const param = this.props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    debugger
    const {
      data,
      referrer,
      colDef: {
        [param]: { linkTo }
      },
      openScreenParams
    } = this.props

    if (Object.keys(openScreenParams).length) {
      this.props.dispatch(
        openScreenAction({
          ...openScreenParams,
          referrer,
          dataId: value
        })
      )
    }
  }

  isRequired = memoize(data => {
    const param = this.props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    const {
      colDef: {
        [param]: { requiredFn, getErrorMessageFn }
      }
    } = this.props

    /*
      sometimes in this crazy sytem,
      you can have an value, but have it be flagged
      (for a variety of reasons)
    */
    if (getErrorMessageFn && requiredFn) {
      return requiredFn(data)
    }

    if (this.state.value || this.state.textFieldVal) {
      return false
    }

    if (requiredFn) {
      return requiredFn(data)
    }

    return false
  })

  render() {
    const param = this.props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    const {
      colDef: {
        [param]: {
          allowMultipleSearches,
          linkTo,
          errorMessage = '',
          getErrorMessageFn,
          isMobile
        }
      },
      indexSearchType,
      data,
      meta,
      indexSearchResultsGrid
    } = this.props
    // console.log('indexSearchCellMAIN', this.props)

    const isOpen = !!(
      indexSearchResultsGrid &&
      indexSearchResultsGrid.rowData &&
      indexSearchResultsGrid.rowData.length
    )

    const searchErrorMessage = getErrorMessageFn
      ? getErrorMessageFn(data)
      : errorMessage

    if (indexSearchType === 'None') {
      return (
        <div
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            height: '100%',
            width: '100%'
          }}
        >
          <TextField
            disabled={this.isInputDisabled()}
            onChange={this.onChange}
            onBlur={this.onBlur}
            value={this.state.textFieldVal}
            id={shortid.generate()}
            inputRef={el => (this.textField = el)}
            inputProps={{
              style: {
                height: '100%',
                minHeight: 24,
                padding: 0,
                width: '100%'
              }
            }}
            style={{ width: '100%' }}
            fullWidth
          />
        </div>
      )
    }

    return this.state.value && !allowMultipleSearches ? (
      <div
        style={{
          color: this.isInputDisabled() ? '#999' : '#444',
          paddingLeft: 0
        }}
      >
        {linkTo ? (
          <a onClick={this.openScreen}>{this.state.value}</a>
        ) : (
          this.state.value
        )}
      </div>
    ) : (
      <div style={{ alignItems: 'center', display: 'flex' }}>
        {this.isRequired(data) ? (
          <div style={{ marginRight: 5 }}>
            {getErrorIcon(searchErrorMessage)}
          </div>
        ) : null}
        <div style={{ maxWidth: '100%', paddingLeft: 3, width: '100%' }}>
          {isMobile ? (
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                minHeight: 28
              }}
            >
              <div style={{ position: 'absolute', left: 0, bottom: 0 }}>
                <IndexSearchMobile
                  {...this.props}
                  _ddiForm={{ form: this.props.form || null }}
                  disabled={this.isInputDisabled()}
                  ref={el => (this.indexSearchCell = el)}
                  openScreenParams={this.props.openScreenParams}
                  onKeyDown={behaviors.onKeyDown}
                  indexSearchType={indexSearchType}
                  partialMatchSearch={this.partialMatch}
                  exactMatchSearch={this.exactMatch}
                  value={this.state.textFieldVal}
                  propertyName={`${this.props.propertyName}.editableGridIndexSearchField`}
                  setField={this.setField}
                  meta={{ ...meta, form: this.props.form }}
                  inputRef={this.inputRef}
                  style={{
                    maxWidth: '100%',
                    minWidth: '100%'
                  }}
                />
              </div>
            </div>
          ) : (
            <IndexSearch
              {...this.props}
              _ddiForm={{ form: this.props.form || null }}
              disabled={this.isInputDisabled()}
              ref={el => (this.indexSearchCell = el)}
              openScreenParams={this.props.openScreenParams}
              onKeyDown={behaviors.onKeyDown}
              indexSearchType={indexSearchType}
              partialMatchSearch={this.partialMatch}
              exactMatchSearch={this.exactMatch}
              value={this.state.textFieldVal}
              propertyName={`${this.props.propertyName}.editableGridIndexSearchField`}
              setField={this.setField}
              meta={{ ...meta, form: this.props.form }}
              inputRef={this.inputRef}
              style={{
                maxWidth: '100%',
                minWidth: '100%'
              }}
            />
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(IndexSearchCell)
